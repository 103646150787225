<template>
  <div class="footer">
    <b-container fluid>
        <b-row>
            <b-col>
              Developed ©{{tahun}} All rights reserved | <a href="#" style="color:inherit;text-decoration: none;">bisnisonlinebgs.com</a>
              <img @click="gantiBahasa('id')" src="../assets/images/icon/nat-id.png" width="25px" style="margin-left:10px">
              <img @click="gantiBahasa('us')" src="../assets/images/icon/nat-usa.png" width="25px" style="margin-left:10px">
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
 data(){
   return{
     tahun:null
   }
 },
 mounted(){
   this.tahun = new Date().getFullYear()
 },
 methods:{
   gantiBahasa(e){
     e.preventDefault
    //  console.log(e)
     if(e == 'id'){
       localStorage.removeItem('bahasa')
       localStorage.setItem('bahasa', 'id')
       location.reload()
     } else if (e == 'us'){
       localStorage.removeItem('bahasa')
       localStorage.setItem('bahasa', 'us')
       location.reload()
     }
     
   }
 }
}
</script>

<style>

</style>