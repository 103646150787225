<template>
  <div id="app">
      <div v-if="timeOut == 0">
        <loadingScreen/>
      </div>
      <div v-else>
        <!-- <Header/> -->
        <Home/>
        <Footer/>
      </div>
  </div>
</template>

<script>
// import Header from './container/Header.vue'
import Home from './components/Home.vue'
import Footer from './container/Footer.vue'
import loadingScreen from './components/loadingScreen.vue'

export default {
  name: 'App',
  components: {
    // Header,
    Home,
    Footer,
    loadingScreen
  },
  data(){
    return{
      isLoading:null,
      timeOut:0
    }
  },
  mounted(){
    setTimeout(() => this.timeOut = 1, 3000);
  }
}
</script>

