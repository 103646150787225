<template>
  <div>
    <!-- <div id="showLocation">
      <location/>
    </div> -->
    <!-- <popUpProduk/> -->
    <Slideshow/>
    <About/>
    <Profile/>
    <whyMust/>
    <Benefit/>
    <Product/>
    <Clinical/>
    <div id="mulaiJualan">
      <startSell/>
    </div>
    <Event/>
    <Testimoni/>
    <Promo/>
    <Join/>
    <!-- <button type="button" class="btn btn-dark toTop-bahasa">
      <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + imgBahasa" width="25">
        </template>
        <b-dropdown-item @click="gantiBahasa('id')"><img src="../assets/images/icon/nat-id.png" width="25"></b-dropdown-item>
        <b-dropdown-item @click="gantiBahasa('us')"><img src="../assets/images/icon/nat-usa.png" width="25"></b-dropdown-item>
      </b-dropdown>
    </button> -->
    
   <!--  <div id="app" class="btn-to-top">
    <go-top></go-top>
    </div> -->
    
    <button type="button" class="btn btn-dark toTop-bahasa" @click="gantiBahasa('id')"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/' + imgBahasa" width="25"></button>
    <a :href="'http://wa.me/' + noTelp + '?text=Halo! Saya tertarik untuk menjadi reseller di Bisnis Online BGS.'"><button type="button" class="btn btn-success toTop-whatsapp"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/whatsapp-icon-trans.png'" width="35"></button></a>
    <!-- <back-to-top><button type="button" class="btn btn-success btn-to-top"><i class="fa fa-chevron-up"></i></button></back-to-top> -->
  </div>
</template> 

<script>
import lazyLoadComponent from '../utils/lazy-load-component';
import SkeletonBox from './SkeletonBox';
import axios from 'axios'
// import BackToTop from 'vue-backtotop';
// import popUpProduk from './content/popUpProduk';
/* import GoTop from '@inotom/vue-go-top' */

const defaultOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: `100%`,
      height: `20em`,
    },
  },
};

// import startSell from './content/startSell'
// import Slideshow from './content/Slideshow'
// import About from './content/About'
// import Profile from './content/Profile'
// import whyMust from './content/whyMust'
// import Benefit from './content/Benefit'
// import Product from './content/Product'
// import Clinical from './content/Clinical'
// import Testimoni from './content/Testimoni'
// import Promo from './content/Promo'
// import Join from './content/Join'


export default {
  components:{
    // startSell,
    // Slideshow,
    // About,
    // Profile,
    // whyMust,
    // Benefit,
    // Product,
    // Clinical,
    // Testimoni,
    // Promo,
    // Join
    
    // BackToTop,
    // popUpProduk,
    // GoTop,

    
    /* Location: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Location`),
    }), */

    Slideshow: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Slideshow`),
    }),
    About: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/About`),
    }),
    Profile: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Profile`),
    }),
    whyMust: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/whyMust`),
    }),
    Benefit: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Benefit`),
    }),
    Product: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Product`),
    }),
    Clinical: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Clinical`),
    }),
    startSell: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/startSell`),
    }),
    Event: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Event`),
    }),
    Testimoni: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Testimoni`),
    }),
    Promo: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Promo`),
    }),
    Join: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./content/Join`),
    })

  },

  data(){
    return{
      show:0,
      username:null,
      noTelp:'62811959901',
      imgBahasa:"lang-id.png"
    }
  },

  mounted(){
    this.username = window.location.pathname.replace("/", "");
    console.log(this.username)
      axios.get(`https://api.bisnisonlinebgs.com/api/content/member/getMemberById/${this.username}`).then(response => {
        this.noTelp = response.data.data[0].mobile_no

        // console.log(response.data.data[0].mobile_no)
      })

      if(localStorage.getItem('bahasa') == null){
        localStorage.setItem('bahasa', 'id')
        this.imgBahasa = "lang-id.png"
      }else if(localStorage.getItem('bahasa') == 'id'){
        this.imgBahasa = "lang-id.png"
      } else if(localStorage.getItem('bahasa') == 'us'){
        this.imgBahasa = "lang-en.png"
      } 

      /* if(this.username == ""){
        document.getElementById("showLocation").style.display = "block";
      }else if(this.username !== ""){
        document.getElementById("showLocation").style.display = "none";
      } */
      
              
  },
  methods:{
    /* scrollToTop() {
      window.scrollTo(0,0);
    }, */

    gantiBahasa(e){
      e.preventDefault
      //  console.log(e)
      // if(e == 'id'){
      //   localStorage.removeItem('bahasa')
      //   localStorage.setItem('bahasa', 'id')
      //   location.reload()
      // } else if (e == 'us'){
      //   localStorage.removeItem('bahasa')
      //   localStorage.setItem('bahasa', 'us')
      //   location.reload()
      // }

      if(localStorage.getItem('bahasa') == 'id'){
        localStorage.removeItem('bahasa')
        localStorage.setItem('bahasa', 'us')
        location.reload()
      } else if(localStorage.getItem('bahasa') == 'us'){
        localStorage.removeItem('bahasa')
        localStorage.setItem('bahasa', 'id')
        location.reload()
      } 
    },

    /* pilihLokasi(e){
     e.preventDefault 
      if(this.username == ""){
        document.getElementById("showLocation").style.display = "block";
        location.reload()
      }else if(this.username !== ""){
        document.getElementById("showLocation").style.display = "none";
        location.reload()
      }
    } */
  }
}
</script>

