<template>
  <div :class="{ loader: true, fadeout: !isLoading }" class="loading">
    <img src="../assets/images/loading.gif" width="60">
  </div>
</template>

<script>
export default {
  props: ["isLoading"]
};
</script>

<style>
.loader {
  background-color: white;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.loading img{
    margin-top: 15rem;
}

.fadeout {
  animation: fadeout 5s forwards;
}

@keyframes fadeout {
  to {
    opacity: 1;
    visibility: hidden;
  }
}
</style>