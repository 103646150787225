import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/style/main.scss'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import VueProgress from 'vue-progress'
import VuePageTransition from 'vue-page-transition'

Vue.use(VuePageTransition)

Vue.use(VueProgress)

var VueScrollTo = require('vue-scrollto'); 
Vue.use(VueScrollTo)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueVideoPlayer)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
 